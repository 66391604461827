html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  background-color: #f8f9fa;
  font-size: 24px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.footer {
  margin-top: 128px;
  padding-bottom: 16px;
  width: 100%;

  font-size: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;

  /* position: -webkit-sticky;
  position: sticky;
  bottom: 0; */
}

.header-item {
  margin-right: 8px;
  margin-left: 8px;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.main-box {
  min-width: 600px;
  margin: 64px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.notSelectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.clickable:hover {
  color: skyblue;
}

.window-center {
  overflow: hidden;
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-items: center;
  justify-content: center;
}
.shopify-title {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-top: 24px;
}

.google-title {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-top: 24px;
}

.card {
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 2px;
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
}
.error {
  color: red;
}

.axis-baseline {
  stroke: #5e637a;
  stroke-width: 1px;
}
.axis-label {
  fill: #5e637a;
}
.dot {
  stroke: white;
  stroke-width: 2px;
}

.dot.data1 {
  fill: #4ad3e5;
}
.dot.data2 {
  fill: #022f22;
}
.dot.data3 {
  fill: #ff0000;
}
.dot.data4 {
  fill: #ff00b3;
}
.dot.data5 {
  fill: #ffc400;
}
.dot.data6 {
  fill: #a6ff00;
}
.dot.data7 {
  fill: #1100ff;
}

.line {
  stroke-width: 2px;
  fill: none;
  transition: 0.3s;
}

.line.data1 {
  stroke: #4ad3e5;
}

.line.data2 {
  stroke: #022f22;
}

.line.data3 {
  stroke: #ff0000;
}

.line.data4 {
  stroke: #ff00b3;
}

.line.data5 {
  stroke: #ffc400;
}

.line.data6 {
  stroke: #a6ff00;
}

.line.data7 {
  stroke: #1100ff;
}

.axis-dotline {
  stroke: #eceef5;
  stroke-width: 1px;
}
.value-indicator {
  stroke: black;
  stroke-width: 1px;
}

.data-group .value-indicator {
  stroke-dasharray: 8, 8;
  stroke: #ccc;
  stroke-width: 1px;
  opacity: 0;
}

.data-group .popup-text {
  display: none;
}

.data-group:hover .value-indicator {
  opacity: 1;
}

.data-group:hover .popup-text {
  display: block;
}

.data-group:hover .dot {
  transform: scale(1.5);
  transform-origin: center;
  transform-box: fill-box;
}

.is-feature-ga{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 0 8px;
    height: 24px;
    background-color: #C3FFFB;
    font-size: 14px;
}

.is-feature-not-ga{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 0 8px;
    height: 24px;
    background-color: #DFDFDF;
    font-size: 14px;
}

.is-feature-enabled{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 0 8px;
    height: 24px;
    background-color: #BFFAB5;
    font-size: 14px;
}

.is-feature-disabled{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 0 8px;
    height: 24px;
    background-color: #FACAB5;
    font-size: 14px;
}

.feature-list-cell{
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
    font-size: 14px;
}

.create-modal-container{
    width: 480px;
}

.create-modal-header{
    font-size: 24px;
    font-weight: 600;
    color: #333333;
    display: flex;
    align-items: center;
    padding: 12px 12px 14px 24px;
    border-top: 4px solid #0078D4;
}

.create-modal-heading{
    color: #333333;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
}

.create-modal-body{
    flex: 4 4 auto;
    padding: 0 24px 24px 24px;
    overflow-y: hidden;
}

.feature-details-text{
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    width: 120px;
}

.div-disabled{
    pointer-events: none;
    opacity: 0.5;
}

.note-green{
    color: green;
}

.note-red{
    color: red;
}

.note-bold{
    font-weight: 600;
}